import React, {Component} from 'react';
import {ActivityIndicator, Alert, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import PropTypes from 'prop-types';
import {Colors} from "app-assets";
import {withTranslation} from "react-i18next";
import {VALUES} from '../../../../setup/config';
import CommentItem from "./CommentItem";


class CommentsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            newComment: '',
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const comments = await this.props.fetchComments();
        this.setState({ comments, loading: false });
    }

    handleAddComment = async () => {
        const content = this.state.newComment.trim();
        if (content) {
            const newState = await this.props.createComment(content, null);
            if (newState) {
                this.setState((prevState) => ({
                    comments: newState,
                    newComment: '',
                }));
            }
        }
    };

    handleDeleteComment = async (commentId) => {
        const {t} = this.props;

        return await new Promise((resolve) => {
            Alert.alert(t('comments.reallyDelete'), t('comments.areYouSureDelete'),[
                {
                    text: t('comments.reallyDelete'),
                    onPress: async () => {
                        const data = await this.props.deleteComment(commentId);
                        if (data) {
                            this.setState({
                                comments: data
                            });
                            resolve(true);
                        }
                        resolve(false);
                    },
                },
                {text: t('back'), onPress: () => resolve(false), style: 'cancel'},
            ]);
        });
    };

    handleReplyComment = async (parentId, content) => {
        content = content.trim();
        if (content) {
            const newState = await this.props.createComment(content, parentId);
            if (newState) {
                this.setState({
                    comments: newState,
                });
                return true;
            }
        }
        return false;
    };

    handleEditComment = async (commentId, content) => {
        //todo
        // const updatedComment = await this.props.editComment(commentId, content);
        // if (updatedComment) {
        //     this.setState((prevState) => {
        //         const comments = prevState.comments.map((comment) =>
        //             comment.id === commentId ? { ...comment, content: { rendered: content } } : comment
        //         );
        //         return { comments };
        //     });
        // }
    };

    render() {
        if (this.state.loading) {
            return <ActivityIndicator size="large" color="#0000ff" style={{marginTop: 20}} />;
        }

        return this.state.comments && this.props.userId ? (
            <View style={styles.container}>
                {this.state.comments.map((comment) => (
                    <CommentItem
                        key={comment.id}
                        comment={comment}
                        onDelete={this.handleDeleteComment}
                        onReply={this.handleReplyComment}
                        onEdit={this.handleEditComment}
                        userId={this.props.userId}
                    />
                ))}
                <View style={{width: '100%', height: 2, backgroundColor: Colors.scholastik.gray, marginBottom: 15}}></View>
                <Text>{this.props.t('comments.leaveCommentHeading')}</Text>

                <View style={styles.inputContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder={this.props.t('comments.commentInputPlaceholder')}
                        value={this.state.newComment}
                        multiline={true}
                        numberOfLines={2}
                        onChangeText={(text) => this.setState({ newComment: text })}
                    />
                    <TouchableOpacity onPress={this.handleAddComment} style={styles.button}>
                        <Text style={styles.buttonText}>{this.props.t('comments.post')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        ) : (
            <View style={styles.container}>
                <Text>{this.props.t('errors.comments.failedToLoad', { domain: VALUES.DOMAIN_NAME })}</Text>
            </View>
        );
    }
}

CommentsSection.propTypes = {
    fetchComments: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 10,
        width: '100%',
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
    },
    input: {
        flex: 1,
        padding: 10,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 10,
        color: Colors.scholastik.black,
        maxHeight: 120,
    },
    button: {
        backgroundColor: Colors.scholastik.purple,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
});

export default withTranslation()(CommentsSection);