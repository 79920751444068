import React, {Component} from 'react';
import {
    Text,
    View,
    ScrollView,
    TouchableOpacity,
    Linking,
    Platform
} from 'react-native';
import {withTranslation} from 'react-i18next';
import {Alert, BackHandler, FileLogger} from "proxy";
import IconI from 'react-native-vector-icons/Ionicons';
import {connect} from 'react-redux';
import styles from './styles';
import {setLoading} from "../../actions/common";
import {Colors} from 'app-assets';
import {Routines} from "../../common";
import IconButton from "../../component/common/iconButton";
import PlainButton from "../../component/common/plainButton";
import Toast from "react-native-toast-message";
import {Events} from "../../common/util";
import {CommonAppLayout, ContentMiddleLayout} from "app-component";
import {FullScreenLayout, TallHeader} from "../../component";
import DeviceInfo from "react-native-device-info";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userCapabilities: undefined
        }
    }

    async reloadUserCapabilities(waiting=true) {
        const {user, dispatch} = this.props;
        if (user?.token) {
            if (waiting) dispatch(setLoading(true));
            const data = await Routines.getUserCapabilities(this.props, waiting);
            if (waiting) dispatch(setLoading(false));
            this.setState({userCapabilities: data});
        }
    }

    async componentDidMount() {
        const {user, navigation} = this.props;
        if (user?.info?.id) {
            const capabilityData = await Routines.getUserCapabilities(this.props, false, user.info.id);
            this.setState({userCapabilities: capabilityData});
            BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        }

        Events.onCustom(this, 'navigationFocus', () => navigation.addListener('focus', async () => {
            this.reloadUserCapabilities(false).catch(e => console.warn('Refresh caps routine failed: ', e));
        }));
    }

    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
        Events.off(this, 'navigationFocus');
    }

    handleBackPress = () => {
        this.autoGoBack();
        return true;
    };

    goBack = () => {
        this.autoGoBack();
    };

    onLogout = () => {
        const {t, dispatch, navigation } = this.props;
        Alert.alert(
            t('logout'),
            t('alert.logoutTxt'),
            [
                {
                    text: t('alert.cancel'),
                    onPress: () => {
                    },
                    style: 'cancel',
                },
                {
                    text: t('alert.ok'),
                    onPress: async () => {
                        dispatch(setLoading(true));
                        await Routines.logout(this.props);
                        dispatch(setLoading(false));
                        this.autoNavigate('LoginScreen');
                    },
                },
            ],
            {cancelable: false}
        );
    };

    removePremiumOldName(name) {
        if (typeof name !== "string") return;
        return name.replace(/prémiový účet/gi, this.props.t('plan'));
    }

    renderUserCapabilities() {
        const {t} = this.props;
        const capabilities = this.state.userCapabilities,
            premiums = capabilities?.roles !== undefined ?
                Object.keys(capabilities.roles).filter(key => key.includes("premium")) : [];

        return <View>{premiums.length > 0 ?
            (premiums.map((role, i) => <View key={String(i)}
                                             style={{flexDirection: "row", justifyContent: "center"}}>
                <IconI name="checkmark-done-circle-outline" size={20} color={Colors.scholastik.greenDark}/>
                <Text> {this.removePremiumOldName(capabilities.roles[role].name)}</Text>
            </View>))
            : (this.state.userCapabilities === undefined ? <Text style={{textAlign: 'center'}}> {t('loading')}</Text>:
                <Text style={{textAlign: 'center'}}> {t('premium.noAvailable')}</Text>)}</View>
    }

    makeCall(phoneNumber) {
        const { t } = this.props;

        if (Platform.OS === 'web') {
            Alert.alert(t('settings.call.doCall'), t('settings.call.doCallNumber', {phone: phoneNumber}));
        } else {
            let url = `tel:${phoneNumber}`;
            Linking.canOpenURL(url)
                .then((supported) => {
                    if (supported) {
                        return Linking.openURL(url);
                    } else {
                        Alert.alert(t('settings.call.error'), t('settings.call.notSupported'));
                    }
                })
                .catch((err) => Alert.alert(t('settings.call.error'), err.message));
        }
    };

    renderSendLogs(supportOnly=true) {
        const {t, user} = this.props;

        return  <View>
            <IconButton
                onPress={async () => {
                    Routines.sendEmail(
                        'info@oscio.cz',
                        t('contactUs.subject', {platform:Platform.OS}),
                        t('contactUs.messageBody')
                    ).then(() => {
                        Toast.show({
                            type: 'info',
                            text1: t('contactUs.thanks'),
                            visibilityTime: 7000,
                        });
                    }).catch(e => {
                        console.log("Email could not be sent!", e);
                        Alert.alert(
                            t(Platform.OS === 'ios' ? 'errors.shareLogs.iosTitle' : 'errors.shareLogs.title'),
                            t(Platform.OS === 'ios' ? 'errors.shareLogs.iosBody' : 'errors.shareLogs.body')
                        );
                    });
                }}
                containerStyle={{marginLeft: 30}}
                IconComponent={IconI}
                icon="mail-outline"
                iconStyle={{color: Colors.scholastik.white, backgroundColor: Colors.scholastik.purple}}
                title={t('contactUs.buttonTitle')}
                body={t('contactUs.buttonDescription')}
            />

            {
                user.info && <IconButton
                    onPress={() => this.makeCall('+420 773 085 038')}
                    IconComponent={IconI}
                    icon="call-outline"
                    iconStyle={{color: Colors.scholastik.white, backgroundColor: Colors.scholastik.orangeDark}}
                    title={t('settings.call.doCall')}
                    containerStyle={{marginLeft: 30}}
                    body={t('settings.call.doCallSmall', {phone: '+420\u00A0773\u00A0085\u00A0038'})}
                />
            }

            {
                !supportOnly && <IconButton
                    onPress={async () => {
                        const osBuild = await DeviceInfo.getBuildId();
                        FileLogger.sendLogFilesByEmail({
                            to: 'horak.j@oscio.cz',
                            subject: t('shareLogs.subject', {platform:Platform.OS }),
                            body: t('shareLogs.messageBodyNoError', {version: DeviceInfo.getVersion(), build: osBuild})
                        }).then(() => {
                            Toast.show({
                                type: 'info',
                                text1: t('shareLogs.thanks'),
                                visibilityTime: 7000,
                            });
                        }).catch(e => {
                            Alert.alert(
                                t(Platform.OS === 'ios' ? 'errors.shareLogs.iosTitle' : 'errors.shareLogs.title'),
                                t(Platform.OS === 'ios' ? 'errors.shareLogs.iosBody' : 'errors.shareLogs.body')
                            );
                        });
                    }}
                    containerStyle={{marginLeft: 30}}
                    IconComponent={IconI}
                    icon="logo-github"
                    iconStyle={{color: Colors.scholastik.white}}
                    title={t('shareLogs.buttonTitle')}
                    body={t('shareLogs.buttonDescriptionNoError')}
                />
            }
        </View>;
    }


    render() {
        const {t, user} = this.props;

        return (
            <CommonAppLayout
                edges={['right', 'left', 'top']}
                color={Colors.scholastik.purple}>
            {user?.token ? (
                    <ScrollView
                        contentContainerStyle={{minHeight: '100%'}}
                        showsVerticalScrollIndicator={Platform.OS === "web"}
                    >
                        <TallHeader>
                            <Text style={styles.title}>{this.props.t('profile.title')}</Text>

                            <View style={{marginLeft: 15, flex: 1, marginBottom: 10}}>
                                <Text style={styles.fullName}>{user?.info?.name}</Text>
                                {/*{user?.info?.description !== '' && (*/}
                                {/*    <Text style={styles.txtContent}>*/}
                                {/*        {user?.info?.description}*/}
                                {/*    </Text>*/}
                                {/*)}*/}
                                {user?.info?.email && (
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        {/*<Image source={Images.iconEmail} style={[styles.icon]}/>*/}
                                        <Text style={styles.txtPhone}>{user?.info?.email}</Text>
                                    </View>
                                )}
                            </View>
                        </TallHeader>
                        <ContentMiddleLayout
                            style={styles.content}
                            containerStyle={{backgroundColor: Colors.scholastik.background}}>

                            <View style={styles.border}/>
                            <View style={{alignSelf: 'center'}}>
                                <View>

                                    <Text style={{
                                        fontFamily: 'Poppins-Medium',
                                        fontWeight: '500',
                                        fontSize: 16, textAlign: 'center', marginBottom: 15
                                    }}>{t('premium.title')}</Text>
                                    {this.renderUserCapabilities()}

                                    <TouchableOpacity
                                        onPress={this.reloadUserCapabilities.bind(this)}
                                        style={{
                                            paddingVertical: 15,
                                            paddingHorizontal: 40,
                                            backgroundColor: Colors.scholastik.purple,
                                            borderRadius: 6,
                                            marginBottom: 25,
                                            marginTop: 15,
                                            alignSelf: 'center',
                                        }}
                                    >
                                        <Text style={{
                                            fontWeight: 'bold',
                                            color: Colors.scholastik.white,
                                            textAlign: 'center'
                                        }}>{t('premium.refresh')}</Text>
                                    </TouchableOpacity>

                                    <View
                                        style={styles.border}
                                    />

                                    <IconButton
                                        onPress={() => this.autoNavigate('SettingsScreen')}
                                        IconComponent={IconI}
                                        icon="cog-outline"
                                        iconStyle={{color: Colors.scholastik.white, backgroundColor: Colors.scholastik.blue}}
                                        title={t('settings.title')}
                                        containerStyle={{marginLeft: 30}}
                                    />

                                    {this.renderSendLogs(false)}

                                </View>
                                <View
                                    style={styles.border}
                                />

                                <IconButton
                                    onPress={this.onLogout}
                                    IconComponent={IconI}
                                    icon="log-out-outline"
                                    iconStyle={{color: Colors.scholastik.redDark, backgroundColor: Colors.scholastik.background}}
                                    title={t('logout')}
                                    containerStyle={{marginLeft: 30, marginTop: 0}}
                                />
                            </View>
                        </ContentMiddleLayout>
                    </ScrollView>
                ) : (
                    <FullScreenLayout>

                        <Text style={[styles.title, {color: Colors.scholastik.textPrimary}]}>{this.props.t('profile.title')}</Text>


                        <View style={{flexDirection: "column", marginBottom: 50}}>

                        <PlainButton
                            onPress={() => this.autoNavigate('LoginScreen')}
                            title={t('login')}
                        />
                        <PlainButton
                            onPress={() => this.autoNavigate('RegisterScreen')}
                            title={t('register')}
                            containerStyle={{backgroundColor: Colors.scholastik.blue}}
                        />
                        </View>
                        {this.renderSendLogs()}

                    </FullScreenLayout>
                )}
            </CommonAppLayout>
        );
    }
}

const mapStateToProps = ({user}) => ({
    user,
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Profile));
