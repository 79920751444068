/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {
    Linking,
    RefreshControl,
    Text,
    View,
} from 'react-native';
import {BackHandler} from "proxy";
import {withTranslation} from 'react-i18next';
import {ListCourses} from 'app-component';
import {HttpAPI, Cache} from 'app-api';
import styles from './styles';
import {connect} from "react-redux";
import {Fade, Placeholder, PlaceholderLine, PlaceholderMedia} from "rn-placeholder";
import LazyLoadingNoContainer from "../../component/common/lazy-listing-no-container";
import {Colors} from 'app-assets';
import {Events, Routines} from "../../common/util";
import {VALUES} from "../../../setup/config";
import InternetConnectionErrorView from "../../component/common/connection-error-view";
import {CommonAppLayout, ContentMiddleLayout, TallHeader} from "app-component";
import {saveCategory, setSelectedCategory} from "../../actions/category";
import {CategoryHeader} from "../../component/categoryHeader";
import {setLoading} from "../../actions/common";

class HomeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowFilter: false,
            page: 1,
            data: [],
            filter: 0,
            isLoading: true,
            //dataFilter: [],
            categorySelect: [],
            categories: [],
            lastShowingCategory: "",
            keySearch: undefined,
            refreshing: false,
            isLoadMore: true,
            orderSubscriptionItem: null,
            showPreviews: true,
        };
        this.isFetchData = true;
    }

    async componentDidMount() {
        const {navigation} = this.props;
        Events.onCustom(this, 'navigationFocus',
            () =>
                navigation.addListener('focus', () => {
                    if (this.state.lastShowingCategory !== this.props.category.activeCategorySlug) {
                        this.refreshData(false);
                    }
                }));
        Events.on(this, 'keywordSearch', this.updateKeywordSearch);
        Events.on(this, 'refresh_with_category', this.refreshWithCate);
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        await this.refreshData(false);
    }

    componentWillUnmount() {
        Events.off(this, 'navigationFocus');
        Events.off(this, 'keywordSearch');
        Events.off(this, 'refresh_with_category');
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    }

    async refreshData(wipe=false) {

        const categoryData = await Routines.getCourseCategoryMeta(this.props) || [];
        const {category} = this.props;
        const activeCategory = categoryData.find(x => x.slug === category.activeCategorySlug);

        if (typeof activeCategory !== "object" || !activeCategory.id) {
            this.hideThisScreen();
            return;
        }
        await this.setState({
            //dataFilter: categories,
            categorySelect: [activeCategory.id],
        });

        //todo uncommented.... -> maybe do not leave here? but it did nothing otherwise....
        await this.getData(false, wipe || this.state.lastShowingCategory !== category.activeCategorySlug);

        const _this = this;
        Routines.userHasNecessaryPremiums(this.props, activeCategory.meta?.subscription || []).then(async result => {
            //show when user cannot view courses, does not check subscriptions
            if (!result) {
                //really check
                const uID = _this.props.user?.info?.id;

                const alreadyRefreshedUserCaps = Cache.read(false, _this.props, 'cached', 'alreadyRefreshedUserCaps');
                if (_this.props.user && uID && !alreadyRefreshedUserCaps) {
                    const caps = await Routines.getUserCapabilities(this.props, true, uID);
                    console.log("Force refresh caps since user has no access", caps);
                    Cache.set('alreadyRefreshedUserCaps', true, _this.props, 'cached', VALUES.EXPIRY_TSTAMP.DAY);
                    return await Routines.userHasNecessaryPremiums(this.props, activeCategory.meta?.subscription || []);
                }
            }
            return result;
        }).then(result => {
            _this.setState({
                showPreviews: !result,
                lastShowingCategory: activeCategory.slug,
                categories: categoryData
            });
        });
    }

    hideThisScreen = () => {
        Cache.set('homeShowDetails', false, this.props, 'cached', VALUES.EXPIRY_TSTAMP.NEVER);
    }

    handleBackPress = () => {
        this.hideThisScreen();
        return true;
    };

    goBack = () => {
        this.hideThisScreen();
    };

    refreshWithCate = async (idCate) => {
        this.isFetchData = false;
        await this.setState({
            categorySelect: [idCate],
            refreshing: true,
            data: [],
            page: 1,
        });
        await this.getData(true);
    };

    updateKeywordSearch = async (value) => {
        await this.setState({keySearch: value});
        this.onRefresh();
    };

    async _fetchData(wipe) {
        const {page, filter, categorySelect, keySearch} = this.state,
            self = this;
        const param = {
            // context: 'view',
            page,
            per_page: 10,
            // search: keySearch,
            // after: null,
            // before: null,
            // exclude: null,
            // include: null,
            // offset: null,
            order: 'asc',
            orderby: 'date',
            // category: null,
            // tag: null,
            // learned: false,
            optimize: false, //get section data if false
        };
        if (keySearch) {
            param.search = keySearch;
        }
        if (filter === 3) param.on_sale = true;
        if (filter === 4) param.popular = true;
        if (filter === 5) {
            param.orderby = 'date';
            param.order = 'desc';
        }
        if (filter === 7) {
            param.orderby = 'date';
            param.order = 'asc';
        }
        if (filter === 6) {
            param.orderby = 'title';
            param.order = 'asc';
        }
        if (filter === 2) {
            param.orderby = 'price';
            param.order = 'desc';
        }
        if (filter === 1) {
            param.orderby = 'price';
            param.order = 'asc';
        }
        if (categorySelect.length > 0) {
            param.category = categorySelect;
        }

        const savedParam = JSON.stringify(param);
        const savedCategory = this.props.category;
        if (savedCategory?.data?.query === savedParam) {
            return savedCategory.data;
        }

        const hadData = wipe ? [] : self.state.data;

        //todo test pagination
        const response = await HttpAPI.course(param).then(r => r?.responseData);
        const newData = Array.isArray(response) && response.filter(e => hadData.length === 0
            || hadData.find((x) => x.id !== e.id));

        const result = {
            courses: hadData.concat(newData),
            hasMoreData: response?.length === 10,
            activePage: page,
            query: savedParam
        };

        const {dispatch} = this.props;
        dispatch(saveCategory(result));
        return result;
    }

    async getData(cached = false, wipe = false) {
        //TODO cached data breaks down redux, removed
        const {page, categorySelect} = this.state,
            self = this;

        const data = cached ? await Cache.getAsync(
            `courseByCategory${categorySelect.toString()}-${page}`,
            this._fetchData.bind(this, wipe),
            this.props,
            'cached',
            {},
            VALUES.EXPIRY_TSTAMP.MONTH,
            () => self.state.refreshing
        ) : await this._fetchData(wipe);

        const courseId = data.courses?.[0]?.id;
        this.setState({
            data: data.courses || [],
            refreshing: false,
            isLoading: false,
            isLoadMore: data.hasMoreData,
            page: data.activePage,
            orderSubscriptionItem: courseId ? {courseId} : null
        });
    }

    showFilter = () => {
        const {isShowFilter} = this.state;
        this.setState({isShowFilter: !isShowFilter});
    };

    async setFilter(value) {
        await this.setState({
            isShowFilter: false,
            filter: value,
            data: [],
        });

        this.onRefresh();
    }

    courseLoadingRenderer = (index) => {
        return (
            <View key={index}>
                <View>
                    <Placeholder style={{
                        padding: 18,
                        paddingBottom: 0,
                        position: "relative"
                    }} Animation={Fade}
                                 Left={() => <PlaceholderMedia style={{width: "100%", borderRadius: 5, height: 80,}}/>}>
                    </Placeholder>
                </View>
                <View>

                    <Placeholder style={{
                        padding: 18,
                        paddingBottom: 0,
                        position: "relative"
                    }} Animation={Fade}>
                        <PlaceholderLine width={20} height={12}/>
                        <PlaceholderLine height={15}/>
                        <PlaceholderLine height={15}/>
                    </Placeholder>
                </View>
            </View>
        );
    };

    // onSelectCate = async (item) => {
    //   const { categorySelect } = this.state;
    //   if (categorySelect.includes(item.id)) {
    //     await this.setState({
    //       data: [],
    //       categorySelect: categorySelect.filter((x) => x !== item.id),
    //       isLoading: true,
    //     });
    //   } else {
    //     await this.setState({
    //       data: [],
    //       categorySelect: [...categorySelect, item.id],
    //       isLoading: true,
    //     });
    //   }
    //
    //   await this.onRefresh();
    // };

    onAnimatedSearch = () => {
        setTimeout(() => {
            this.inputSearch.focus();
        }, 200);
    };

    // renderItemFilter = ({ item }) => {
    //   const { categorySelect } = this.state;
    //   return (
    //     <TouchableOpacity
    //       onPress={() => this.onSelectCate(item)}
    //       style={{
    //         height: 30,
    //         paddingHorizontal: 19,
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         backgroundColor: categorySelect.includes(item.id) ? '#000' : Colors.scholastik.white,
    //         borderRadius: 60,
    //         borderWidth: 1,
    //         borderColor: '#EBEBEB',
    //         marginRight: 10,
    //       }}
    //     >
    //       <Text
    //         style={[
    //           styles.txtItemFilter,
    //           { color: categorySelect.includes(item.id) ? Colors.scholastik.white : '#858585' },
    //         ]}
    //       >
    //         {item.name}
    //       </Text>
    //     </TouchableOpacity>
    //   );
    // };

    handleLoadMore = async () => {
        const {page, isLoadMore} = this.state;
        if (!isLoadMore) return;
        await this.setState({
            showFooter: true,
            page: page + 1,
            refreshing: true,
        });
        await this.getData(false);
        await this.setState({showFooter: false});
    };

    onRefresh = async () => {
        try {
            await this.setState({
                refreshing: true,
                data: [],
                page: 1,
            });
            await this.getData(false);
            await this.setState({
                refreshing: false,
            });
        } catch (e) {
            console.warn(e);
        }
    };

    refreshScreen() {
        const {refreshing} = this.state;

        return (
            <RefreshControl
                refreshing={refreshing}
                onRefresh={this.onRefresh}
                progressViewOffset={30}
            />
        );
    }

    onCloseKeywordSearch = async () => {
        await this.setState({
            data: [],
            keySearch: null,
        });

        this.onRefresh();
    };

    async onClickChooseCategory(slug) {
        const { dispatch } = this.props;
        if ((this.state.categories || []).find(x => x.slug === slug)) {
            await dispatch(setSelectedCategory(slug));
            await dispatch(setLoading(true));
            await this.refreshData(true);
            await dispatch(setLoading(false));
        } else {
            console.warn("Attempt to set invalid slug!");
        }
    }

    render() {
        const {
            refreshing,
            showFooter,
            data,
            isLoading,
            showPreviews,
            categories,
            orderSubscriptionItem
        } = this.state;
        const {t, navigation, category} = this.props;
        const categoryName = categories.find(x => x.slug === category.activeCategorySlug)?.name || "";

        // console.log(data && data.map(x => x.id))

        const renderData =
            showPreviews && orderSubscriptionItem?.courseId ? [orderSubscriptionItem,...data] : data;

        return (
            <CommonAppLayout
                edges={['right', 'left', 'top']}
                color={Colors.scholastik.purple}>
                <CategoryHeader
                    title={t('courses.title') + " " + (categoryName.toUpperCase() || "- " + t('loading'))}
                    categories={categories}
                    selectedCategory={category.activeCategorySlug}
                    onSelect={slug => this.onClickChooseCategory(slug)}
                    onBack={() => this.hideThisScreen()}
                    goToHomePage={() => Linking.openURL(VALUES.SITE_URL)}
                    navigation={this.props.navigation}
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                >
                </CategoryHeader>

                <ContentMiddleLayout containerStyle={{backgroundColor: Colors.scholastik.background}}>
                    {/*<View*/}
                    {/*  style={{*/}
                    {/*    marginTop: 26,*/}
                    {/*    flexDirection: 'row',*/}
                    {/*    justifyContent: 'space-between',*/}
                    {/*    marginHorizontal: 16,*/}
                    {/*    alignItems: 'center',*/}
                    {/*    backgroundColor: '#ffffff'*/}

                    {/*  }}*/}
                    {/*>*/}
                    {/*  <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, backgroundColor: '#ffffff'}}>*/}
                    {/*    {keySearch && (*/}
                    {/*      <>*/}
                    {/*        <Text numberOfLines={1} style={styles.txtSearch}>*/}
                    {/*          {t('courses.searching', { keySearch })}*/}
                    {/*        </Text>*/}
                    {/*        <TouchableOpacity*/}
                    {/*          style={{ marginLeft: 6 }}*/}
                    {/*          hitSlop={hitSlop}*/}
                    {/*          onPress={this.onCloseKeywordSearch}*/}
                    {/*        >*/}
                    {/*          <IconI name="close" size={20} />*/}
                    {/*        </TouchableOpacity>*/}
                    {/*        /!* <Text style={styles.txtSearch}>*/}
                    {/*      Showing 1-25 courses of 365 courses (api chưa trả về)*/}
                    {/*    </Text> *!/*/}
                    {/*      </>*/}
                    {/*    )}*/}
                    {/*  </View>*/}
                    {/*  <View>*/}
                    {/*    <TouchableOpacity*/}
                    {/*      onPress={this.showFilter}*/}
                    {/*      style={styles.viewFilter}*/}
                    {/*    >*/}
                    {/*      {filter === 1 && (*/}
                    {/*        <Text style={styles.txtFilter}>Price: Low to High</Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 2 && (*/}
                    {/*        <Text style={styles.txtFilter}>Price: High to Low</Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 3 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.sale')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 4 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.popular')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 5 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.newest')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 7 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.oldest')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 6 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.title')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}
                    {/*      {filter === 0 && (*/}
                    {/*        <Text style={styles.txtFilter}>*/}
                    {/*          {t('courses.filters.filter')}*/}
                    {/*        </Text>*/}
                    {/*      )}*/}

                    {/*      <IconI name="caret-down-outline" color="#C5C5C5" size={8} />*/}
                    {/*    </TouchableOpacity>*/}
                    {/*  </View>*/}
                    {/*</View>*/}
                    {!isLoading && !refreshing && data.length < 1 ? (
                        <InternetConnectionErrorView onPress={this.onRefresh}/>
                    ) : (
                        <>
                            {isLoading && (
                                <LazyLoadingNoContainer visible={isLoading} renderer={this.courseLoadingRenderer}/>
                            )}

                            <ListCourses
                                data={renderData}
                                navigation={navigation}
                                autoNavigation={this.autoNavigate.bind(this)}
                                user={this.props.user}
                                extraData={this.state}
                                contentContainerStyle={{paddingTop: 20, paddingBottom: 250}}
                                refreshScreen={this.refreshScreen()}
                                nextPage={this.handleLoadMore}
                                refreshing={refreshing}
                                showFooter={showFooter}
                                showPreviews={showPreviews}
                                // horizontal={}
                            />


                            {/*{isShowFilter && (*/}
                            {/*    <TouchableWithoutFeedback*/}
                            {/*        onPress={() => {*/}
                            {/*            this.setState({isShowFilter: false});*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Animatable.View*/}
                            {/*            style={[styles.viewUpdateRole, {}]}*/}
                            {/*            onPress={() => {*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <TouchableWithoutFeedback>*/}
                            {/*                <Animatable.View*/}
                            {/*                    delay={100}*/}
                            {/*                    style={[*/}
                            {/*                        styles.viewModalFilter,*/}
                            {/*                        {right: -width + 127 + 16, top: 150},*/}
                            {/*                    ]}*/}
                            {/*                >*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(0)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 0 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.default')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(1)}>*/}
                            {/*                        <Text*/}
                            {/*                          style={[*/}
                            {/*                            styles.txtFilterItem,*/}
                            {/*                            filter === 1 && { color: '#000' },*/}
                            {/*                          ]}*/}
                            {/*                        >*/}
                            {/*                          Price: Low to High*/}
                            {/*                        </Text>*/}
                            {/*                      </TouchableOpacity>*/}
                            {/*                      <TouchableOpacity onPress={() => this.setFilter(2)}>*/}
                            {/*                        <Text*/}
                            {/*                          style={[*/}
                            {/*                            styles.txtFilterItem,*/}
                            {/*                            filter === 2 && { color: '#000' },*/}
                            {/*                          ]}*/}
                            {/*                        >*/}
                            {/*                          Price: High to Low*/}
                            {/*                        </Text>*/}
                            {/*                      </TouchableOpacity> */}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(6)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 6 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.title')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(5)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 5 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.newest')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(7)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 7 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.oldest')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(3)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 3 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.sale')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                    <TouchableOpacity onPress={() => this.setFilter(4)}>*/}
                            {/*                        <Text*/}
                            {/*                            style={[*/}
                            {/*                                styles.txtFilterItem,*/}
                            {/*                                filter === 4 && {color: '#000'},*/}
                            {/*                            ]}*/}
                            {/*                        >*/}
                            {/*                            {t('courses.filters.popular')}*/}
                            {/*                        </Text>*/}
                            {/*                    </TouchableOpacity>*/}
                            {/*                </Animatable.View>*/}
                            {/*            </TouchableWithoutFeedback>*/}
                            {/*        </Animatable.View>*/}
                            {/*    </TouchableWithoutFeedback>*/}
                            {/*)}*/}
                        </>)}
                </ContentMiddleLayout>
            </CommonAppLayout>
        );
    }
}

const mapStateToProps = ({cached, category, user}) => ({
    cached, category, user
});
const mapDispatchToProps = (dispatch) => ({dispatch});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(HomeDetails));
