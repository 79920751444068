/* eslint-disable no-nested-ternary */
import React, {PureComponent} from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Dimensions,
    Animated, Platform,
} from 'react-native';
import {Alert} from "proxy";
//absolute-positioned button does not react to normal react-native instance
import { HttpAPI } from 'app-api';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Colors} from "app-assets";
import IconI from "react-native-vector-icons/Ionicons";

class CourseSectionType extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            animation: new Animated.Value(20),
            statistics: undefined,
        };
        this.tapTime = 0;
        this.onNavigate = this.props.onNavigateLearning;
    }

    fetchStatistics(itemList) {
        if (!this.state.statistics) {
            const self = this;
            HttpAPI.quizStats({id_list: itemList.map(x => x.id)}).then(response => {
                response = response?.responseData;

                if (typeof response === "object") {
                    for (let id in response) {
                        let stats = response[id];
                        if (stats.user_mark !== undefined && stats.user_mark !== "") {
                            stats.user_mark = `${stats.user_mark}%`;
                        }
                    }
                    self.setState({
                        statistics: response
                    });
                } else {
                    self.setState({
                        statistics: null
                    });
                }
            });
        }
    }

    getDuration(i) {
        return typeof i.duration === "string" ? i.duration : "";
    }

    onAdvancedNavigation (item, forceStart=false) {
        if (Platform.OS !== "web") {
            if (Date.now() - this.tapTime > 100) return;
        }
        if (!item) {
            //todo
            const {t} = this.props;
            Alert.alert(t('singleCourse.noData'), t('singleCourse.noDataDetails'));
        }
        this.onNavigate(item, 0, forceStart);
    }

    _getTimeFromSeconds(seconds) {
        let h = String(Math.floor(seconds / 3600));
        let m = String(Math.floor(seconds / 60) % 60);
        if (Number(h) < 10) return `~${m}min`;
        return `~${h}h ${m}min`;
    }

    async checkQuizIsOutOfTime(quiz) {
        const _this = this;
        const {t} = this.props;

        const data = await HttpAPI.quiz(quiz.id).then(r => r?.responseData);
        const time = Number.parseInt(data?.results?.total_time);
        if (time < 1) {
            //fake tap:
            Alert.alert(t('singleCourse.outOfTimeWarn'), t('singleCourse.outOfTimeWarnContinue'),
                [{
                    text: t('singleCourse.outOfTimeButton'),
                    onPress: () => {
                        _this.tapTime = Date.now();
                        _this.onAdvancedNavigation(quiz);
                    },
                    style: 'cancel',
                }]);
        } else if (time) {
            quiz.duration = quiz.duration + t('singleCourse.remaining', {remaining: this._getTimeFromSeconds(time)});
        }
    }

    isQuizTimedAndActive (quiz) {
        const progress = this.getDuration(quiz) !== "" && quiz.graduation === "in-progress";
        if (progress) {
            const loading = quiz.__fetchedRemainingTime;
            if (!loading) {
                quiz.__fetchedRemainingTime = true;
                //todo: quite expensive check, what if API changes and they add remaining time to the API?
                this.checkQuizIsOutOfTime(quiz);
            }
        }
        return this.getDuration(quiz) !== "" && quiz.graduation === "in-progress"
    };

    getStartTextByProgress(graduation) {
        switch (graduation) {
            case "in-progress": return this.props.t('singleCourse.btnContinue');
            case "failed": return this.props.t('singleCourse.btnFixFail');
            case "passed": return this.props.t('singleCourse.btnRetake2');
            case "": return this.props.t('singleCourse.btnStartNow');
            default: return '';
        }
    }

    renderPropsOfQuiz(i, item, primaryColor, animated) {
        const duration = this.getDuration(item);
        const statistics = this.state.statistics?.[item.id];
        let percentil = statistics?.["percentil"];

        function toPercent(x) {
            if (typeof x === "string") {
                x = Number.parseFloat(x);
            }
            if (x <= 1) {
                x = x * 100;
            }
            return Math.round((x * 100)) / 100;
        }

        let isPercentil = false;
        if (!percentil || isNaN(percentil)) {
            const progress = statistics?.["user_progress"];
            if (statistics?.["user_progress"] > 0) {
                percentil = `${toPercent(Number.parseFloat(statistics["user_progress"]))} %`;
            } else if (Number.isFinite(progress)) {
                percentil = '0%';
            } else {
                percentil = this.props.t('singleCourse.notAvailable') + ' ' + this.props.t('singleCourse.precentilNotAvailableHint');
            }
        } else {
            isPercentil = true;
            percentil = `${toPercent(percentil)} ${this.props.t(statistics?.["heuristic"] === "latest" ? "singleCourse.latestShown" : 'singleCourse.bestShown')}`;
        }

        return <View key={String(i)} style={{marginHorizontal: 10, marginTop: 6}}>
            <Text style={{fontSize: 17, color: Colors.scholastik.white, marginBottom: 7}}>{item.title}</Text>

            <View style={{marginBottom: 7}}>
                <Text style={{color: Colors.scholastik.white}}>
                    <Text style={{fontWeight: 'bold'}}>{this.props.t('singleCourse.itemDuration')}</Text>
                    {' ' + (duration || this.props.t('singleCourse.noLimit'))}
                </Text>

                {
                    //statistics are missing number of questions in quiz & progress track (how many are solved)
                    statistics !== undefined ? (
                        statistics !== null ? (
                            <View>
                                <Text style={{color: Colors.scholastik.white}}>
                                    <Text style={{fontWeight: 'bold'}}>{this.props.t(isPercentil ? 'singleCourse.percentil' : 'singleCourse.progress')}</Text>
                                    {' ' + percentil}
                                </Text>
                                <Text style={{color: Colors.scholastik.white, marginTop: 5}}>
                                    <Text>{this.props.t('singleCourse.mark')}</Text>
                                    {' ' + (statistics["user_mark"] || "-")}
                                </Text>
                                <Text style={{color: Colors.scholastik.white}}>
                                    <Text>{this.props.t('singleCourse.solutions')}</Text>
                                    {' ' + (statistics["solutions"] || "-")}
                                </Text>
                            </View>
                        ) : <Text style={{color: Colors.scholastik.white, margin: 10}}>{this.props.t('errors.titleGeneral')}</Text>
                    ) : <Text style={{color: Colors.scholastik.white, margin: 10}}>{this.props.t('loading')}</Text>
                }

            </View>

            {
                //allow immediate run of quiz
                (item.graduation === "failed" || item.graduation === "passed") &&
                <TouchableOpacity
                    key={String(i) + "-retry"}
                    onPressIn={() => this.tapTime = Date.now()}
                    onPressOut={() => {
                        this.props.onSetSelectedId("");
                        this.onAdvancedNavigation(item, true);
                    }}
                    style={[styles.buttonReStart, {marginTop: 10, marginBottom: 10}]}>

                    <View style={[styles.buttonStartContainer, {alignItems: "center", justifyContent: "center"}]}>
                        <Text style={[styles.buttonStartText, {color: primaryColor}]}
                              ellipsizeMode="tail" numberOfLines={1}>{this.props.t('singleCourse.btnRepeat')}</Text>
                    </View>

                </TouchableOpacity>

            }

            <TouchableOpacity
                key={String(i)}
                onPressIn={() => this.tapTime = Date.now()}
                onPressOut={() => this.onAdvancedNavigation(item, true)}
                style={[styles.buttonStart, {marginTop: 10, marginBottom: 15}]}>

                <View style={[styles.buttonStartContainer, {alignItems: "center", justifyContent: "center"}]}>
                    <Text style={[styles.buttonStartText, {color: primaryColor}]}
                          ellipsizeMode="tail" numberOfLines={1}>{this.getStartTextByProgress(item.graduation)}</Text>
                    {this.renderStatus(item, animated)}
                </View>

            </TouchableOpacity>

        </View>;
    }

    renderStatus(item, animated) {
        Animated.spring(this.state.animation, {
            toValue: 0,
            friction: 1,
            tension: 20,
            duration: 2000,
            useNativeDriver: true
        }).start();
        return <View style={{flexDirection: 'row', flexShrink: 1, marginRight: 10}}>
            {animated ? (
                <Animated.View style={{transform: [{translateY: this.state.animation}]}}>
                    <IconI name="alert-circle-outline" style={[styles.iconPreview,
                        {color: Colors.scholastik.redDark}]}/>
                </Animated.View>
            ) : (<View style={{flexDirection: "row"}}>
                {item.preview && item.status !== 'completed' && (
                    <IconI name="eye-outline" style={styles.iconPreview}/>
                )}
                {item.locked && (
                    <IconI name="lock-closed" style={[styles.iconPreview, {color: "#4E4E4E"}]}
                           color="#4E4E4E"/>
                )}
                {item.duration !== '' && (
                    <Text ellipsizeMode="tail" numberOfLines={1}
                          style={styles.totalHours}>{item.duration}</Text>
                )}
                {item.status === 'completed' && (
                    <IconI name="checkmark-circle-outline" style={styles.iconPreview}/>
                )}

            </View>)
            }
        </View>;
    }

    renderPropsOfLesson(i, item, primaryColor, animated) {
        return <View style={{marginHorizontal: 10, marginTop: 3}} key={String(i)}>
            <TouchableOpacity
                onPressIn={() => this.tapTime = Date.now()}
                onPressOut={() => this.onAdvancedNavigation(item)}
                style={styles.buttonStart}>
                <View pointerEvents="none" style={styles.buttonStartContainer}>
                    <Text style={[styles.buttonStartText, {color: primaryColor}]}
                          ellipsizeMode="tail" numberOfLines={1}>{item.title}</Text>
                    {this.renderStatus(item, animated)}
                </View>
            </TouchableOpacity>
        </View>;
    }

    renderPopupButtons(elems, isQuiz) {
        const color = isQuiz ? Colors.scholastik.purple : Colors.scholastik.blue;
        const infoRenderer = isQuiz ? this.renderPropsOfQuiz.bind(this) : this.renderPropsOfLesson.bind(this);
        return elems.map((item, i) => {
            const animated = this.isQuizTimedAndActive(item);
            return infoRenderer(i, item, color, animated);
        });
    };

    render() {
        const {t, section, onSetSelectedId, styled, selectedItemId, isDisabled} = this.props;

        const getId = (type) => `${section.id}_${type}`,
            self = this;

        const setSelectedId = (type, items=undefined) => {
            let id = getId(type);
            onSetSelectedId(selectedItemId === id ? "" : id);
            self.setState({
                animation: new Animated.Value(20)
            });

            if (items && type === 'quiz') {
                self.fetchStatistics(items);
            }
        };


        const quizzes = section.items.filter(item => item.type === 'lp_quiz'),
            lessons = section.items.filter(item => item.type === 'lp_lesson'),
            hasQuizzes = quizzes.length > 0,
            hasLessons = lessons.length > 0,
            isLessonFinished = lessons.every(l => l.graduation !== "" && l.graduation !== "in-progress"),
            isQuizFinished = quizzes.every(q => q.graduation !== "" && q.graduation !== "in-progress"),
            isLessonPopupOpen = hasLessons && getId('lesson') === selectedItemId && !isDisabled,
            isQuizPopupOpen = hasQuizzes && getId('quiz') === selectedItemId && !isDisabled,
            closed = !(isQuizPopupOpen || isLessonPopupOpen);

        const active = quizzes.some(this.isQuizTimedAndActive.bind(this));

        return (
            <View>
                <View style={[styles.container, styled]}>
                    <View>
                        <Text style={styles.title}>{section.title}</Text>
                    </View>
                    <View style={{marginLeft: 25, alignSelf: "flex-start", flexDirection: 'column'}}>
                        {/*<Text style={{fontWeight: 'bold'}}>Percentil: nejde</Text>*/}
                        {/*<Text>Úspěšnost: nejde</Text>*/}
                        {/*<Text>Hotovo: nejde</Text>*/}
                        {active && <Text style={{
                            fontWeight: 'bold',
                            color: Colors.scholastik.redDark
                        }}>{t('singleCourse.inProgressWarn')}</Text>}
                    </View>
                    <View style={{
                        marginTop: 20,
                        marginHorizontal: 20,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flex: 1,
                    }}>
                        {
                            hasQuizzes && (<TouchableOpacity onPress={() => setSelectedId('quiz', quizzes)}
                                                  style={[styles.halfWidthButton, {
                                                      opacity: isDisabled ? .5 : 1,
                                                      borderColor: Colors.scholastik.purple,
                                                      backgroundColor: isQuizFinished ? Colors.scholastik.white : Colors.scholastik.purple
                                                  }]} disabled={!! isDisabled}>

                                    <Text style={[styles.buttonText,
                                        {color: isQuizFinished ? Colors.scholastik.purple : Colors.scholastik.white}]}>
                                        {t('courses.types.quiz')}</Text>
                                    {
                                        isQuizFinished &&
                                        <IconI name="checkmark-circle-outline" style={styles.roundedIcon}/>
                                    }
                                </TouchableOpacity>
                            )
                        }
                        {
                            hasLessons && (
                                <TouchableOpacity onPress={() => setSelectedId('lesson')}
                                                  style={[styles.halfWidthButton, {
                                                      opacity: isDisabled ? .5 : 1,
                                                      borderColor: Colors.scholastik.blue,
                                                      backgroundColor: isLessonFinished ? Colors.scholastik.white : Colors.scholastik.blue
                                                  }]} disabled={!! isDisabled}>
                                    <Text style={[styles.buttonText,
                                        {color: isLessonFinished ? Colors.scholastik.blue : Colors.scholastik.white}]}>
                                        {t('courses.types.theory')}
                                    </Text>
                                    {
                                        isLessonFinished &&
                                        <IconI name="checkmark-circle-outline" style={styles.roundedIcon}/>
                                    }
                                </TouchableOpacity>)
                        }

                    </View>

                </View>
                {isQuizPopupOpen && //todo implement as FlatList
                    (<View style={{position: "relative", zIndex: 3, elevation: 3}}>
                        <View
                            style={styles.popupLeft}
                            contentContainerStyle={styles.popupContent}
                            showsVerticalScrollIndicator={Platform.OS === "web"}
                        >
                            <View>
                                {this.renderPopupButtons(quizzes, true)}
                            </View>
                        </View>
                        <View style={[styles.arrowUp, {
                            left: "25%",
                            borderBottomColor: Colors.scholastik.purple,
                        }]}/>
                    </View>)
                }

                {isLessonPopupOpen && //todo implement as FlatList
                    (<View style={{position: "relative", zIndex: 3, elevation: 3}}>
                        <View style={styles.popupRight}
                              contentContainerStyle={styles.popupContent} showsVerticalScrollIndicator={Platform.OS === "web"}
                        >
                            {this.renderPopupButtons(lessons, false)}
                        </View>
                        <View style={[styles.arrowUp, {right: "25%", borderBottomColor: Colors.scholastik.blue,}]}/>
                    </View>)
                }

                {closed && <View style={{height: 10}}/>}
            </View>

        );
    }
}

const styles = {
    container: {
        backgroundColor: Colors.scholastik.white,
        flex: 1,
        paddingTop: 15,
        borderRadius: 7,
        width: '100%',
        paddingBottom: 15,
    },
    title: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        fontSize: 19,
        lineHeight: 26,
        marginHorizontal: 25,

    },
    halfWidthButton: {
        justifyContent: "center",
        paddingHorizontal: 10,
        flex: 1,
        paddingTop: 7,
        paddingBottom: 5,
        borderRadius: 5,
        marginHorizontal: 7,
        flexDirection: "row",
        borderWidth: 2,
    },
    buttonText: {
        color: Colors.scholastik.white, fontSize: 14, fontFamily: 'Poppins'
    },
    popupRight: {
        flexDirection: 'column',
        backgroundColor: Colors.scholastik.blue,
        width: '100%',
        paddingVertical: 15,
        marginHorizontal: 15,
        marginBottom: 20,
        borderRadius: 7,
        padding: 15,
        marginLeft: 0
    },
    popupLeft: {
        alignSelf: "flex-start",
        flexDirection: 'column',
        backgroundColor: Colors.scholastik.purple,
        width: '100%',
        borderRadius: 7,
        marginBottom: 20,
        marginHorizontal: 15,
        paddingVertical: 15,
        padding: 15,
        marginLeft: 0
    },
    roundedIcon: {
        fontFamily: "Poppins",
        fontSize: 20,
        color: Colors.scholastik.greenDark,
        lineHeight: 25,
        paddingLeft: 5,
        backgroundColor: Colors.scholastik.white,
        marginTop: -3
    },
    popupContent: {
        justifyContent: 'center',
        alignContent: 'center',
    },
    iconBack: {
        height: 14,
        width: 14,
        resizeMode: 'contain',
        tintColor: Colors.scholastik.textPrimary,
    },
    white: {
        color: Colors.scholastik.white,
    },
    buttonStartContainer: {
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "row",
        display: 'flex',
    },
    buttonReStart: {
        paddingTop: 7,
        paddingBottom: 7,
        backgroundColor: Colors.scholastik.white,
        width: '100%',
        borderRadius: 3,
    },
    buttonStart: {
        paddingTop: 7,
        paddingBottom: 7,
        backgroundColor: Colors.scholastik.white,
        width: '100%',
        borderRadius: 3,
    },
    buttonStartText: {
        marginLeft: 20,
        marginRight: 10,
        fontSize: 16,
        flex: 1,
        flexGrow: 2,
        flexShrink: 2,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
    },
    arrowUp: {
        position: "absolute",
        width: 20,
        height: 20,
        top: -15,
        backgroundColor: 'transparent',
        borderTopWidth: 0,
        borderRightWidth: 15,
        borderBottomWidth: 20,
        borderLeftWidth: 15,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderLeftColor: 'transparent',
    },
    iconPreview: {
        fontSize: 16,
        color: Colors.scholastik.greenDark,
        marginLeft: 5,
        lineHeight: 25,
        paddingLeft: 5,
        backgroundColor: Colors.scholastik.white,
        marginTop: -3
    },
    totalHours: {
        fontFamily: 'Poppins-ExtraLight',
        fontSize: 12,
        color: '#6c6c6c',
        height: 20,
        fontWeight: '300',
        maxWidth: 55,
        lineHeight: 20,
        marginTop: 1,
    },
};

const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapDispatchToProps
)(withTranslation()(CourseSectionType));
