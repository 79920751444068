import * as React from 'react';
import {SafeAreaProvider, SafeAreaView} from "react-native-safe-area-context";
import {Colors, Images} from "app-assets";
import {VALUES} from "../../setup/config";
import {Image, TouchableOpacity, View, Text, Platform, StyleSheet, KeyboardAvoidingView} from "react-native";
import Modal from 'react-native-modal';
import {withTranslation} from 'react-i18next';

class CommonAppLayout extends React.Component {
    render() {
        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={{backgroundColor: this.props.color || Colors.scholastik.background, flex: 1}}>
                <SafeAreaView style={{flex: 1}}
                              forceInset={this.props.forceInset || {}}
                              edges={this.props.edges || ['right', 'left', 'bottom', 'top']}>
                    {this.props.children}
                </SafeAreaView>
            </KeyboardAvoidingView>
        );
    }
}

class ContentMiddleLayout extends React.Component {

    constructor(props) {
        super(props);

        this.width = this.props.containerStyle?.width || '100%';
        this.height = this.props.containerStyle?.height || '100%';
        this.maxWidth = this.props.containerStyle?.maxWidth || VALUES.WINDOW_MAX_WIDTH;
        if (typeof this.maxWidth === "number") this.maxWidth = Math.min(this.maxWidth, VALUES.WINDOW_MAX_WIDTH);

        this.style = this.props.style ? {...this.props.style} : {};
    }

    render() {
        return (
            <View style={[{width: '100%', height:'100%', position: 'relative', alignSelf: 'center'},
                this.props.containerStyle || {}]}>
                <View
                    {...this.props}
                    style={[
                        {
                            maxWidth: this.maxWidth,
                            alignSelf: 'center',
                            width: this.width,
                            height: this.height,
                            position: 'relative',
                        },
                        this.style
                    ]}
                ></View>
            </View>
        );
    }
}

class FullScreenLayout extends React.Component {
    render() {
        return (
            <View style={{
                backgroundColor: Colors.scholastik.background,
                alignSelf: "center",
                alignItems: 'center',
                justifyContent: 'center',
                width: "100%",
                flexDirection: "column",
                flex: 1,
                height: '100%',
            }}>{this.props.children}</View>
        );
    }
}

class TallHeader extends React.Component {
    render() {
        return <View
            style={{
                minHeight: 170,
                width: '100%',
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                backgroundColor: Colors.scholastik.purple,
                maxHeight: 170,
                height: 170,
            }}
        >
            {
                this.props.backgroundProp && <View style={{display: 'flex', flex: 1}}>
                    {this.props.backgroundProp}
                </View>

            }
            <ContentMiddleLayout containerStyle={{position: "absolute"}}>
                {
                    this.props.onBack && <TouchableOpacity
                        onPress={this.props.onBack}
                        style={{marginTop: 30, marginLeft: 40, zIndex: 100}}
                        hitSlop={{ top: 30, bottom: 30, left: 30, right: 50 }}
                    >
                        <Image source={Images.iconBack} style={{tintColor: Colors.scholastik.white, height: 14,
                            width: 14,}}></Image>
                    </TouchableOpacity>
                }
                {
                    Platform.OS === "web" && this.props.goToHomePage && <TouchableOpacity
                        onPress={this.props.goToHomePage}
                        style={{marginTop: 30, marginLeft: 40, zIndex: 100, right: 40, top: 0, position: "absolute"}}
                        hitSlop={{ top: 30, bottom: 30, left: 30, right: 50 }}>
                        <Text
                            style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'Poppins', paddingHorizontal: 18,
                                paddingVertical: 7, borderRadius: 7,
                                color: Colors.scholastik.textPrimary, backgroundColor: Colors.scholastik.background}}>
                            {this.props.t('homeButton')}
                        </Text>
                    </TouchableOpacity>
                }

                <View
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 2,
                        right: 0,
                        flex: 1 ,
                    }}
                >

                    {this.props.children}
                </View>
            </ContentMiddleLayout>
        </View>;
    }
}
TallHeader = withTranslation()(TallHeader);

class SafeModal extends React.Component {
    render() {
        const { children, ...otherProps } = this.props;

        return <Modal{...otherProps}>
            <SafeAreaProvider>
                {children}
            </SafeAreaProvider>
        </Modal>;
    }
}

export {
    CommonAppLayout,
    FullScreenLayout,
    ContentMiddleLayout,
    TallHeader,
    SafeModal
};
