export function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = n =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

    const r = Math.round(255 * f(0));
    const g = Math.round(255 * f(8));
    const b = Math.round(255 * f(4));

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function numberToColor(number) {
    // Convert hash to hue (0-360)
    const hash = Math.abs(number);
    const hue = hash % 360; // Limit hue to [0, 360)

    // Set fixed saturation and lightness for pleasant colors
    const saturation = 70;  // Saturation at 70%
    const lightness = 60;   // Lightness at 60%

    // Return the final color
    return hslToHex(hue, saturation, lightness);
}

function hashCode(text) {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

// Function to calculate the color difference (Euclidean distance)
function colorDistance(color1, color2) {
    const rgb1 = color1.match(/\w{2}/g).map(x => Number.parseInt(x, 16));
    const rgb2 = color2.match(/\w{2}/g).map(x => Number.parseInt(x, 16));
    return Math.pow(rgb1[0] - rgb2[0], 2) +
        Math.pow(rgb1[1] - rgb2[1], 2) +
        Math.pow(rgb1[2] - rgb2[2], 2);
}

// Main function to generate a color that is not too close to EXISTING_COLOR
export function generateDistinctColorFromText(text, avoidColor) {
    let newColor;
    const hash = hashCode(text);
    const add = Math.sqrt(Math.abs(hash)) % 19;
    let it = 0;
    do {
        newColor = numberToColor(hash + it*add*7);
        it++;
        //empirically set threshold
    } while (colorDistance(newColor, avoidColor) < 17000 && it < 10);

    return newColor;
}