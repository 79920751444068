import {Cache, HttpAPI, setToken} from 'app-api';
import {readPropOrThrow, safeReadMeta} from "../index";
import {VALUES,
    SUBSCRIPTIONS_IAP,
    SUBSCRIPTIONS_TO_PREMIUM_MAP} from "../../../setup/config";
import {saveUserToken, setRecentSearch, setUser} from "../../actions/user";
import {Linking, Platform} from "react-native";
import {
    flushFailedPurchasesCachedAsPendingAndroid,
    getAvailablePurchases,
    setup,
    initConnection,
} from "react-native-iap";
import {subscriptionPurchased, subscriptionTimedOut} from "../../actions/subscriptions";
import jwtDecode from "jwt-decode";
import DeviceInfo from "react-native-device-info/src/index";
import {asyncWithTimeout} from "./index";
import sharedCacheIterator from "../../reducers/sharedCacheIterator";
import {eraseQuestionStats, updatePinsData, updatePinsMetaData} from "../../actions/pins";
import qs from "qs";
import {FileLogger} from "proxy";
import {saveCategory, setSelectedCategory} from "../../actions/category";
import {LRUCache} from "./lruCache";

let _allowUserExpirationCheck = true;

// cached comments only in memory for now
const cachedComments = new LRUCache(99);

export const Routines = {

    /**
     * Logout with correct cleaning of the state
     * @param props
     * @returns {Promise<Awaited<unknown>[]>}
     */
    async afterLogin(props, loginResponse) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::afterLogin', 'function');

        const uID = Number.parseInt(loginResponse.user_id);
        if (uID < 1) throw `Invalid user id in the response data '${uID}'!`;

        await dispatch(saveUserToken(loginResponse.token));
        await dispatch(setUser(loginResponse));
        setToken(loginResponse.token);

        //todo get message from the response
        const responseUser = await HttpAPI.getUser(uID).then(r => r?.responseData);

        if (!responseUser) {
            await dispatch(saveUserToken(null));
            await dispatch(setUser(null));
            await setToken(null);
            throw "User probably not present on the website, although registered at multisite.";
        }
        await dispatch(setUser(responseUser));
        const caps = await Routines.getUserCapabilities(props, true, uID);
        console.debug("Login", loginResponse, caps);

        //do not wait for the finishing of this procedure
        this.getUserQuestionStats(props, uID).catch(e => console.warn("Failed to load user quiz stats", e));
    },

    /**
     * Logout with correct cleaning of the state
     * @param props
     * @returns {Promise<Awaited<unknown>[]>}
     */
    async logout(props) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::logout', 'function');
        console.log("User logout.");
        return Promise.all([
            dispatch(setUser(null)),
            dispatch(saveCategory(null)),
            dispatch(saveUserToken(null)),
            dispatch(setRecentSearch([])),
            dispatch(eraseQuestionStats()),
            setToken(null),
            Cache.eraseAll(props), //todo fixme something goes wrong when erasing due to upgrade
        ]);
    },

    stopUserExpirationDetection(doStop) {
        _allowUserExpirationCheck = doStop;
    },

    /**
     * Check token whether expired
     * @param props
     */
    async checkUserExpired(props) {
        const user = readPropOrThrow(props, 'user', 'Routines::checkUserExpired', 'object');
        if (_allowUserExpirationCheck && user && user.token) {
            const tokenDecode = jwtDecode(user.token);
            const expires = tokenDecode.exp - (Date.now() / 1000);
            console.log("Check: token expires in " + expires + " seconds");

            // If less than tow hours until expiration, request login
            if (expires < 7200) {
                // logout will erase user which in turn erases token prop and we won't repeat this :)
                await this.logout(props);
                return true;
            }
        }
        return false;
    },

    async sendEmail(to, subject, body, options = {}) {
        try {
            const { cc, bcc } = options;

            let url = `mailto:${to}`;

            // Create email link query
            const query = qs.stringify({
                subject: subject,
                body: body,
                cc: cc,
                bcc: bcc
            });

            if (query.length) {
                url += `?${query}`;
            }

            const canOpen = await Linking.canOpenURL(url);
            if (!canOpen) throw new Error('Provided URL can not be handled');
            return Linking.openURL(url);
        } catch (e) {
            console.log("Email failure, try file logger!", e);
            return await FileLogger.sendLogFilesByEmail({
                to: to,
                subject: subject,
                body: body
            })
        }
    },

    /**
     * Retrieve and cache user stats
     * @param props
     * @param userId
     * @param forceReload
     * @returns {Promise<*>}
     */
    async getUserQuestionStats(props, userId) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::getUserQuestionStats', 'function');

        const response = await HttpAPI.questionsGetStats(userId).then(r => r?.responseData);
        const data = response?.data || {};


        //todo try to remove server load: questionsGetStats can avoid getting quiz data, since we have the data from  getAllCourseData
        //read names of sections and courses for pins headers
        const courseData = await Routines.getAllCourseData(props);
        const quizMap = {};
        for (let course of courseData) {
            for (let section of course.sections) {
                for (let quiz of section.items) {
                    quizMap[quiz.id] = {
                        courseName: course.name,
                        courseCategories: course.categories
                    }
                }
            }
        }
        //save quiz_id -> {metadata} map
        dispatch(updatePinsMetaData(quizMap));
        console.log("updated");
        //save question pins
        dispatch(updatePinsData(Object.values(data)));
    },

    /**
     * Refresh user with token or logout if unsuccessful
     * @param props
     * @returns {Promise<void>}
     */
    async refreshUser(props) {
        const user = readPropOrThrow(props, 'user', 'Routines::refreshUser', 'object');
        const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::refreshUser', 'function');
        if (user?.token) {
            const tokenDecode = jwtDecode(user.token);
            const response = await HttpAPI.getUser(tokenDecode.data.user.id).then(r => r?.responseData);
            if (response) dispatch(setUser(response));
        } else {
            console.warn("Cannot refresh user! Token does not exist!");
            await Routines.logout(this.props);
        }
    },

    /**
     * //todo course data could be retrieved here instead of directly, reuse! (home screen - search for HttpAPI.course() calls)
     *   todo not cached since this breaks down redux :/
     *    steps to reproduce: login, refresh app (state erased)
     * retrieve all courses data
     * @param props
     * @returns {Promise<*>}
     */
    async getAllCourseData(props) {
        const data = await HttpAPI.course({
            //todo: problematic if course count exceeds 100! probably will not happen
            per_page: 100
        });
        let courseData = data?.responseData;
        return courseData?.map(course => {
            return {
                id: course.id,
                name: course.name,
                slug: course.slug,
                status: course.status,
                categories: course.categories,
                sections: course.sections.map(section => ({
                    id: section.id,
                    title: section.title,
                    items: section.items
                }))
            };
        });

        // return await Cache.getAsync('allCourseData',
        //     async _ => {
        //         const data = await HttpAPI.course({
        //             //todo: problematic if course count exceeds 100! probably will not happen
        //             per_page: 100
        //         });
        //         let courseData = data?.responseData;
        //         return courseData?.map(course => {
        //             return {
        //                 id: course.id,
        //                 name: course.name,
        //                 slug: course.slug,
        //                 status: course.status,
        //                 categories: course.categories,
        //                 sections: course.sections.map(section => ({
        //                     id: section.id,
        //                     title: section.title,
        //                     items: section.items
        //                 }))
        //             };
        //         });
        //     }, props, 'user', {}, VALUES.EXPIRY_TSTAMP.WEEK);
    },


    /**
     * Get User Capabilities List
     * @param props properties with user cache
     * @param forceReload ignore cached values if true
     * @param userId default undefined, get for given user, read from cache if not given
     * @returns {Promise<*>}
     */
    async getUserCapabilities(props, forceReload=false, userId=undefined) {
        if (!userId) {
            const user = readPropOrThrow(props, 'user', 'Routines::getUserCapabilities', 'object');
            userId = user?.info?.id;

            if (!userId || !user.token) return {}; //hack, somewhere the app was calling this method when user logged out...
        }

        return await Cache.getAsync('capabilities',
            async _ => {
                let capabilities = await HttpAPI.getUserCapabilities({id: userId}).then(r => r?.responseData);
                if (capabilities?.data?.hasOwnProperty('status') && capabilities.data.status !== 200) {
                    return {};
                }
                return capabilities;
            }, props, 'user', {}, VALUES.EXPIRY_TSTAMP.DAY, (cache, def) => forceReload);
    },

    /**
     * Read all (meta)data for course categories
     * @param props
     * @param forceReload
     * @returns {Promise<*>}
     */
    async getCourseCategoryMeta(props, forceReload=false) {
        const categoryStore = readPropOrThrow(props, 'category', 'Routines::getCourseCategoryMeta', 'object');
        const categoryData = await Cache.getAsync('cachedCategories',
            async () => await HttpAPI.getCategoriesFullInfo().then(r => r?.responseData || []),
            props, 'cached', [], VALUES.EXPIRY_TSTAMP.NEVER,
            (cache, def) =>  forceReload || Array.isArray(cache) && cache.length === 0);

        if (!categoryStore.activeCategorySlug) {
            const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::getCourseCategoryMeta', 'function');
            if (categoryData && categoryData.length) {
                await dispatch(setSelectedCategory(categoryData[0].slug));
            }
        }
        return categoryData;
    },

    /**
     * @param courseCategoryList a list of assigned categories - objects with properties
     * @param listSlugKey key property to the courseCategoryList object list, value to search for in cachedCategories slugs
     * @param cachedCategories category list retrieved by HttpAPI.getCategoriesFullInfo
     *  this object contains a record whether certain category requires a premium account
     * @returns {[]} a list of categories (objects) that are attached to the specified course
     */
    getRequiredCategoriesInfoFromCourseList(courseCategoryList, listSlugKey, cachedCategories) {
        const result = new Set();
        for (let c of courseCategoryList) {
            let ctgKey = c[listSlugKey] || '__undefined__';
            let categoryData = cachedCategories.find(x => x.slug === ctgKey);
            if (categoryData) result.add(categoryData);
        }
        return Array.from(result);
    },

    /**
     * @param courseCategoryList a list of assigned categories - objects with properties
     * @param listSlugKey key property to the courseCategoryList object list, value to search for in cachedCategories slugs
     * @param cachedCategories category list retrieved by HttpAPI.getCategoriesFullInfo
     *  this object contains a record whether certain category requires a premium account
     * @returns {[]} a list of account roles required from the user
     */
    getRequiredPremiumTagsFromCourseList(courseCategoryList, listSlugKey, cachedCategories) {
        return Routines.getRequiredCategoriesInfoFromCourseList(courseCategoryList, listSlugKey, cachedCategories)
            .map(x => safeReadMeta(
                x?.meta,'subscription', false
            )).filter(x => typeof x === 'string');
    },

    /**
     * @param props
     * @param premiumList
     * @returns {Promise<boolean|*>} true if user has permission to access the course
     */
    async userHasNecessaryPremiums(props, premiumList) {
        const user = readPropOrThrow(props, 'user', 'Routines::checkUserCanViewCourse', 'object');
        let id = user?.info?.id;
        if (!id) {
            console.info("userHasNecessaryPremiums requires user ID! Access denied.", id);
            return false;
        }
        const capabilities = await Routines.getUserCapabilities(props);

        let allowedCourse = true;
        for (let premium of premiumList) {
            allowedCourse &= capabilities.caps && capabilities.caps[premium];
        }
        return allowedCourse;
    },

    //todo create logout routine - should invalidate cache

    /**
     * @param courseData course object retrieved by HttpAPI.courseDetail
     * @param props
     * @returns {Promise<boolean|*>} true if user has permission to access the course
     */
    async checkUserCanViewCourse(courseData, props) {
        let categoryInfo = await Routines.getCourseCategoryMeta(props);
        if (!courseData || !categoryInfo) {
            console.warn("Missing data for couse permission evaluation", courseData, categoryInfo);
            return false;
        }

        const premiums = Routines.getRequiredPremiumTagsFromCourseList(courseData.categories, 'slug', categoryInfo);
        return await this.userHasNecessaryPremiums(props, premiums);
    },

    /**
     * Perform unified routine checking before the user is granted access to
     * the data, should be called before each course access (then it is verified thy can load the course data)
     * @param props props with user, subscription stores and dispatch method
     * @param courseData course info object from general courses overview
     * @param onSuccess
     * @param onInactiveSubscriptionRequired
     * @param onDifferentSubscriptionRequired
     * @returns {Promise<boolean>}
     */
    async performNecessaryUserChecksBeforeCourseStart(props,
                                                      courseData,
                                                      onSuccess,
                                                      onInactiveSubscriptionRequired,
                                                      onDifferentSubscriptionRequired) {

        //necessary to check the active sub now in case it timed out, user privs will change
        let subscription, hasPremium = false;
        if (courseData?.id &&
            Cache.get(`permissionCourse${courseData.id}`, false, props,
                'user', VALUES.EXPIRY_TSTAMP.DAY)) {

            console.log("Cached permission granted.")
            await onSuccess(null);
            return true;
        }

        console.log('Check for active premiums.');
        try {
            hasPremium = await Routines.checkUserCanViewCourse(courseData, props);
        } catch (e) {
            console.debug('Failed to read user caps', e, '. Has premium:', hasPremium);
        }

        if (hasPremium) {
            await onSuccess(null);
        } else {

            console.log('Check for active subscription.');
            if (Platform.OS !== "web") {
                //no IAP in web
                try {
                    subscription = await Routines.getActiveSubWithUpdateSafely(props, undefined, 60000);
                } catch (e) {
                    console.debug('Failed to read active subscription', e, 'subscription', subscription);
                }
            }

            const categoryInfo = await Routines.getCourseCategoryMeta(props);
            const premiums = Routines.getRequiredPremiumTagsFromCourseList(courseData.categories, 'slug', categoryInfo);

            if (premiums.length > 0) {
                if (!subscription) {
                    await onInactiveSubscriptionRequired(subscription, premiums);
                } else {
                    const activePremiums = SUBSCRIPTIONS_TO_PREMIUM_MAP[subscription] || [];
                    const unownedPremiums = premiums.filter( premium => !activePremiums.includes(premium) );
                    if (premiums.length > 0 && unownedPremiums.length === 0) {

                        //todo now we just add all of them, later check the subset needed only
                        try {
                            const user = readPropOrThrow(props, 'user', 'Routines::performNecessaryUserChecksBeforeCourseStart', 'object');
                            console.debug("Not synchronized! User capabilities delayed add request:", premiums);
                            const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::performNecessaryUserChecksBeforeCourseStart', 'function');

                            await dispatch(subscriptionPurchased( {
                                userId: user.info?.id, purchase: "<<ACTIVE PREMIUM: '" + subscription + "'>>",
                                receipt: "<<DELAYED REQUEST>>",
                                timeStamp: Date.now(),
                                receivedPremiums: premiums
                            }));

                            const userPremiums = await Routines._recordUserPremiumStatusOnWeb(
                                jwtDecode(user.token)?.data?.user.id, //user for sure logged in at this point
                                {
                                    receipt: "<<DELAYED REQUEST>>",
                                    purchase: "<<ACTIVE PREMIUM: '" + subscription + "'>>",
                                    operatingSystem: Platform.OS,
                                    appVersion: DeviceInfo.getVersion(),
                                    timestamp: Date.now(),
                                },
                                premiums.map(p => ({code: p, command: 'add'})),
                                props
                            )

                            console.debug("Delayed premium request finished:", userPremiums)
                        } catch (e) {
                            console.error("Delayed premium synchronization failed:", e);
                        }
                    }
                    console.log("User unowned, required premium list:", unownedPremiums);
                    if (unownedPremiums.length < 1) {
                        hasPremium = true;
                        await onSuccess(subscription);
                    } else {
                        await onDifferentSubscriptionRequired(subscription, premiums);
                    }
                }
            } else throw "Should not have happened: no premiums required!";
        }
        Cache.set(`permissionCourse${courseData.id}`, hasPremium, props, 'user', VALUES.EXPIRY_TSTAMP.DAY);
        return hasPremium;
    },

    /**
     * Send HttpAPI.[...] request without waiting and cache failure if failed.
     * @param context react component that contains 'failed' store in it's props
     * @param cacheUniqueName unique name to 'failed' store
     * @param requestClientHandlerName HttpAPI.<> method name to repeat on failure
     * @param requestHandlerParamArray the method params
     * @param onSuccess callback on success
     * @param onFailure callback on failure
     */
    sendRequestWithoutWaitingAndHandleFailureByCaching(
        context,
        cacheUniqueName,
        requestClientHandlerName,
        requestHandlerParamArray=[],
        onSuccess=_=>{},
        onFailure=_=>{}) {

        const saveFailedRequest = (reason) => {
            const existing = Cache.get(cacheUniqueName, undefined, context.props, 'failed',
                VALUES.EXPIRY_TSTAMP.NEVER);

            if (existing === undefined) {
                Cache.set(cacheUniqueName, {
                        params: requestHandlerParamArray,
                        clientHandlerName: requestClientHandlerName,
                        error: reason,
                        failCount: 0,
                    },
                    context.props, 'failed', VALUES.EXPIRY_TSTAMP.NEVER);
            } else {
                existing.failCount++;
                Cache.set(cacheUniqueName, existing, context.props, 'failed', VALUES.EXPIRY_TSTAMP.NEVER);
            }
            onFailure();
        };

        if (!Array.isArray(requestHandlerParamArray)) requestHandlerParamArray = [requestHandlerParamArray];

        HttpAPI[requestClientHandlerName](...requestHandlerParamArray).then(response => {
            response = response?.responseData;
            if (!response || typeof response?.code === "string" && response?.code !== 'success') {
                saveFailedRequest(response.code);
            } else {
                onSuccess(response);
            }
        }).catch(e => {
            saveFailedRequest(e);
        });
    },

    /**
     * Re-Send HttpAPI.[...] request without waiting and cache failure if failed.
     * @param context react component that contains 'failed' store and dispatch in it's props
     * @param onFinish function to call on success finish
     */
    retryFailedRequestsFromCache(context, onFinish=undefined) {
        let counter = 0;
        const callback = () => {
            counter--;
            if (counter < 1 && onFinish) {
                onFinish();
                onFinish = undefined;
            }
        };

        sharedCacheIterator(context.failed, (vKey, vMetaKey) => {
            const cached = Cache.get(vKey, undefined, context, 'failed', VALUES.EXPIRY_TSTAMP.NEVER);
            if (cached === undefined) {
                Cache.set(vKey, undefined, context, 'failed', VALUES.EXPIRY_TSTAMP.ALWAYS);
                return;
            }

            if (cached.failCount >= VALUES.UNDER_HOOD_REQUESTS_RETRY_COUNT) {
                Cache.set(vKey, undefined, context, 'failed', VALUES.EXPIRY_TSTAMP.ALWAYS);
                console.warn("Abort re-sending request", vKey, cached.clientHandlerName);
                return;
            }

            console.debug("Re-sending request, ", vKey);
            counter++;

            Routines.sendRequestWithoutWaitingAndHandleFailureByCaching(
                context,
                vKey,
                cached.clientHandlerName,
                cached.params,
                (response) => {
                    Cache.set(vKey, undefined, context, 'failed', VALUES.EXPIRY_TSTAMP.ALWAYS);
                    console.debug("Success re-sending request", vKey, cached.clientHandlerName);
                    callback();
                },
                () => {
                    callback();
                }
            );
        }, () => {
            counter++;
            callback();
        }); //important to have something called if no iteration happened
    },

    async _recordUserPremiumStatusOnWeb(userId, metadata, premiumList, props) {
        let response = await HttpAPI.updateWebPremiums({
            id: userId,
            meta: metadata,
            subscriptions: premiumList
        }).then(r => r?.responseData);
        console.debug("User capabilities change request having: ", response);

        if ((response?.data?.hasOwnProperty('status') && response.data.status !== 200)
             || !response.caps) { //require caps prop to be succesfull
            return {
                status: 'error',
                code: 'SERVER_FAILURE_ADD_REQUEST',
                data: response
            };
        }
        Cache.set('capabilities', response, props, 'user');

        return {
            status: 'success',
            code: 'SUCCESS',
            data: response
        }
    },

    async initIap() {
        setup({storekitMode:'STOREKIT2_MODE'});
        await initConnection().then(() => {
            // we make sure that "ghost" pending payment are removed
            // (ghost = failed pending payment that are still marked as pending in Google's native Vending module cache)

            if (Platform.OS === "android") {
                flushFailedPurchasesCachedAsPendingAndroid()
                    .catch(() => {
                        // exception can happen here if:
                        // - there are pending purchases that are still pending (we can't consume a pending purchase)
                        // in any case, you might not want to do anything special with the error
                    });
            }
        });
    },

    /**
     * Register purchased subscription in system and update user premium account on the web
     * so that ::getActiveSubWithUpdate can unset active premiums on subscription timeout
     * @param purchase
     * @param receipt
     * @param props props object with user store and dispatch function
     * @returns {Promise<void>}
     */
    async recordSubscriptionPurchase(purchase, receipt, props) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::recordSubscriptionPurchase', 'function');
        const user = readPropOrThrow(props, 'user', 'Routines::recordSubscriptionPurchase', 'object');

        // fixme record user purchase in more stable way
        // if (user) {
        //     const purchaseRef = collection(firestore, `users/${user.uid}/purchases`);
        //     const sponsorRef = collection(firestore, `sponsors`);
        //
        //     addDoc(purchaseRef, {
        //         purchase,
        //         receipt,
        //     });
        //
        //     addDoc(sponsorRef, {
        //         purchase,
        //         user,
        //     });
        // }

        const capabilities = await Routines.getUserCapabilities(props);
        let premiums = [];
        if (capabilities.caps) {
            //user receives following premium accounts atop already owned ones
            premiums = SUBSCRIPTIONS_TO_PREMIUM_MAP[purchase.productId]?.filter(
                premium => !capabilities.caps[premium]
            );
        }

        if (!Array.isArray(premiums)) return {
            status: 'error',
            code: 'UNKNOWN_SUBSCRIPTION',
            data: undefined
        };

        let id = user.info?.id;
        if (!id && user?.token) {
            const tokenDecode = jwtDecode(user.token);
            id = tokenDecode.data.user.id;
        }

        if (id) {
            await dispatch(subscriptionPurchased(purchase.productId, {
                userId: id, purchase: purchase, receipt: receipt,
                timeStamp: Date.now(),
                receivedPremiums: premiums.length > 0 ? premiums : [...SUBSCRIPTIONS_TO_PREMIUM_MAP[purchase.productId]]
            }));

            console.debug("User capabilities add request:", premiums);
            return await Routines._recordUserPremiumStatusOnWeb(
                id,
                {
                    receipt: receipt,
                    purchase: purchase,
                    operatingSystem: Platform.OS,
                    appVersion: DeviceInfo.getVersion(),
                    timestamp: Date.now(),
                },
                SUBSCRIPTIONS_TO_PREMIUM_MAP[purchase.productId].map(
                    premium => ({code: premium, command: 'add'})),
                props
            );
        } else {
            return {
                status: 'error',
                code: 'USER_NOT_LOGGED_IN',
                data: undefined
            };
        }
    },

    /**
     * Receive active subscription and remove privileges: a robust wrapper for Routines.getActiveSubWithUpdate
     * @param props props object with stores subscription and user, and dispatch function
     * @param magicCode in case of uncaught exception or timeout, returns this code
     * @param timeout timeout after which the procedure fails
     * @returns {Promise<string>} the subscription product ID, undefined/null/empty (not a valid sub) or a magic
     *   code in case of a failure
     */
    async getActiveSubWithUpdateSafely(props, magicCode, timeout=15000) {
        let activeSubscription;
        try {
            activeSubscription = await asyncWithTimeout(
                Routines.getActiveSubWithUpdate(props),
                timeout,
                true
            );
        } catch (e) {
            console.error('The active subscription check did not finish.', e);
            activeSubscription = magicCode;
        }
        return activeSubscription;
    },

    /**
     * Receive active subscription and remove privileges if the subscription is not valid
     * @param props props object with stores subscription and user, and dispatch function
     * @returns {Promise<string>}
     */
    async getActiveSubWithUpdate(props) {
        const activeSub = await Routines.getActiveSubscriptionId();
        if (!activeSub) {
            //todo what if not an internet connection? will probably fail although it might be active...

            const subs = readPropOrThrow(props, 'subscription', 'Routines::getActiveSubWithUpdate', 'object');
            for (let recordedSubscription of SUBSCRIPTIONS_IAP) {
                const record = subs[recordedSubscription];
                if (record) {
                    const dispatch = readPropOrThrow(props, 'dispatch', 'Routines::getActiveSubWithUpdate', 'function');
                    const user = readPropOrThrow(props, 'user', 'Routines::getActiveSubWithUpdate', 'object');
                    console.debug("Removing received premiums from ", recordedSubscription, ": ", record.receivedPremiums);

                    if (user?.token) {
                        const tokenDecode = jwtDecode(user.token),
                            premiumsToRemove = Array.isArray(record.receivedPremiums) && record.receivedPremiums.length > 0 ?
                                record.receivedPremiums : SUBSCRIPTIONS_TO_PREMIUM_MAP[recordedSubscription];
                        //todo with general implementation?
                        let response = await HttpAPI.updateWebPremiums({
                            id: tokenDecode.data.user.id,
                            meta: {
                                timestamp: Date.now()
                            },
                            subscriptions: premiumsToRemove.map(
                                premium => ({code: premium, command: 'remove'}))
                        }).then(r => r?.responseData || {});
                        dispatch(subscriptionTimedOut(recordedSubscription));

                        if (response.data?.hasOwnProperty('status') && response.data.status !== 200) {
                            response = {};
                        }
                        console.debug("User capabilities removed:", record.receivedPremiums, ", having: ", response);
                        Cache.set('capabilities', response, props, 'user');
                    }
                }
            }
        }
        return activeSub;
    },


    getActiveSubscriptionId: async () => {
        await Routines.initIap();

        if (Platform.OS === 'ios') {
            console.debug('Get Subscription::getAvailablePurchases');
            const availablePurchases = await getAvailablePurchases();
            console.log("AVAIL",availablePurchases)

            if (!Array.isArray(availablePurchases) || availablePurchases.length === 0) {
                return undefined;
            }

            const sortedAvailablePurchases = availablePurchases.sort(
                (a, b) => b.transactionDate - a.transactionDate,
            );
            const latestAvailableReceipt =
                sortedAvailablePurchases[0];

            //todo check how to correctly verify recepit on IOS : not possible with storekit 2
            // const isTestEnvironment = __DEV__;
            //
            // console.debug('Get Subscription::validateReceiptIos', latestAvailableReceipt);
            //
            // //App store connect shared secret - primary
            // const decodedReceipt = await validateReceiptIos({
            //     receiptBody: {
            //         'receipt-data': latestAvailableReceipt,
            //         password: `3110d4739ba948468d9b4e12791da0e1`,
            //     },
            //     isTest: isTestEnvironment,
            // });
            //
            // console.log('Decoded receipt: ', decodedReceipt?.latest_receipt_info, 'of', availablePurchases.length);
            //
            // if (decodedReceipt) {
            //     const {latest_receipt_info: latestReceiptInfo} = decodedReceipt;
            //     console.debug("Receipt type: ", typeof latestReceiptInfo);
            //
            //     let expirationInMilliseconds;
            //     if (Array.isArray(latestReceiptInfo)) {
            //         const extract = latestReceiptInfo.map(info => Number(info.expires_date_ms));
            //         expirationInMilliseconds = Math.max(...extract);
            //     } else {
            //         expirationInMilliseconds = Number(
            //             // @ts-ignore
            //             latestReceiptInfo?.expires_date_ms
            //         );
            //     }
            //
            //     const nowInMilliseconds = Date.now();
            //     console.debug("Compare", expirationInMilliseconds, nowInMilliseconds);
            //
            //     if (expirationInMilliseconds > nowInMilliseconds) {
            //         return sortedAvailablePurchases[0]?.productId;
            //     }
            // } else console.debug('Failed to decode receipt.')

            return latestAvailableReceipt?.productId;
        }

        if (Platform.OS === 'android') {
            await flushFailedPurchasesCachedAsPendingAndroid();
            const availablePurchases = await getAvailablePurchases();

            for (let i = 0; i < availablePurchases.length; i++) {
                if (SUBSCRIPTIONS_IAP.includes(availablePurchases[i].productId)) {
                    return availablePurchases[i].productId;
                }
            }

            return undefined;
        }
    },

    async getCommentsFor(id, user, allowCache=true) {
        const cached = allowCache && cachedComments.get(id);
        if (!cached) {
            const url = new URL(`${VALUES.SITE_URL}/wp-json/wp/v2/comments`);
            url.searchParams.append("post", id);
            url.searchParams.append("per_page", 100);

            const data = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user.token}`
                },
            }).then(async response => {
                if (!response.ok) {
                    console.warn("Failed to fetch comments!", await response.text());
                    return null;
                }
                return await response.json();
            }).then(resultData => {

                const buildCommentTree = (comments) => {
                    const commentMap = {};
                    const roots = [];

                    comments.forEach((comment) => {
                        commentMap[comment.id] = { ...comment,  children: [] };
                    });

                    comments.forEach((comment) => {
                        if (comment.parent === 0) {
                            roots.push(commentMap[comment.id]);
                        } else {
                            if (commentMap[comment.parent]) {
                                commentMap[comment.parent].children.push(commentMap[comment.id]);
                            }
                        }
                    });

                    // Sort roots only in descending order
                    const sortCommentsByDateDesc = (a, b) => new Date(b.date) - new Date(a.date);
                    comments.sort(sortCommentsByDateDesc);
                    return roots;
                };

                return resultData ? buildCommentTree(resultData) : null;
            });
            cachedComments.put(id, data);
            return data;
        }
        return cached;
    },

    async addComment(id, comment) {
        // TODO move to API
        const url = new URL(`${VALUES.SITE_URL}/wp-json/wp/v2/comments`);
        const originalPostData = await this.getCommentsFor(id, comment.user);

        const data = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${comment.user.token}`
            },
            body: JSON.stringify({
                author: comment.user.info.id,
                author_name: comment.user.info.name,
                author_email: comment.user.info.email,
                author_user_agent: await DeviceInfo.getUserAgent(),
                content: comment.content,
                parent: comment.parent,
                post: id,
            })
        }).then(async response => {
            if (!response.ok) {
                throw await response.json();
            }
            return await response.json();
        });

        if (!data) return null;

        const findCommentById = (comments, id) => {
            for (let comment of comments) {
                if (comment.id === id) {
                    return comment;
                }
                const childComment = findCommentById(comment.children, id);
                if (childComment) {
                    return childComment;
                }
            }
            return null;
        };

        const insertNewComment = (comments, newComment) => {
            newComment.children = [];

            if (newComment.parent === 0) {
                // roots are sorted by date newest top, children are newest bottom
                comments.unshift(newComment);
            } else {
                const parentComment = findCommentById(comments, newComment.parent);
                if (parentComment) {
                    parentComment.children.push(newComment);
                }
            }
        };
        insertNewComment(originalPostData, data);
        cachedComments.put(id, originalPostData);
        return originalPostData;
    },

    async deleteComment(postId, id, user) {
        const originalPostData = await this.getCommentsFor(postId, user);

        const url = new URL(`${VALUES.SITE_URL}/wp-json/wp/v2/comments/${id}`);
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            },
        });

        if (!response.ok) {
            let errorData = await response.text();
            try {
                errorData = JSON.parse(errorData);
            } catch (e) {
                console.error("Attempt to delete comment: Response:", errorData);
                errorData = {message: 'Unknown Error!'};
            }
            throw new Error(errorData.message || 'Error deleting comment');
        }

        const findCommentById = (comments, id) => {
            for (let comment of comments) {
                if (comment.id === id) {
                    return comment;
                }
                const childComment = findCommentById(comment.children, id);
                if (childComment) {
                    return childComment;
                }
            }
            return null;
        };

        const deleteCommentById = (comments, commentId) => {
            for (let i = 0; i < comments.length; i++) {
                if (comments[i].id === commentId) {
                    comments.splice(i, 1);
                    return true;
                }
                if (deleteCommentById(comments[i].children, commentId)) {
                    return true;
                }
            }
            return false;
        };

        deleteCommentById(originalPostData, id);
        cachedComments.put(postId, originalPostData);
        return originalPostData;
    },

    setCachedCommentsFor(id, data) {
        cachedComments.put(id, data);
    }
};